import React, {useState, useEffect} from 'react';
import NavigationBar from '../../../../Components/Nav/NavigationBar';
import { useCarrito } from '../../../Carrito/CarritoContext';
import { Container, Row, Col, Toast } from 'react-bootstrap';
import herminioInicio from '../../../../assets/Productos/herminio/Fotos/Herminio_foto_inicio.webp';
import herminioIMG from '../../../../assets/carrito/Herminio.webp';
import usos from '../../../../assets/Productos/uriel_y_polo/Fotos/usos.png';
import vista from '../../../../assets/Productos/herminio/Señalizacion_materialidad/Herminio_Señalizacion_materialidad.webp';
import dimensiones from '../../../../assets/Productos/herminio/Dimensiones/Herminio_dimensiones.webp';
import foto1 from '../../../../assets/Productos/herminio/Fotos/Herminio_izquierda.webp';
import foto2 from '../../../../assets/Productos/herminio/Fotos/Herminio_derecha.webp';
import config1 from '../../../../assets/Productos/herminio/Configuraciones/Herminio_configuracion_1.webp';
import config2 from '../../../../assets/Productos/herminio/Configuraciones/Herminio_configuracion_2.webp';
import final from '../../../../assets/Productos/herminio/Fotos/Herminio_inferior.webp';
import GaleryHerminio from '../../../../Components/CarrouselProductos/HerminioCarrousel/GaleryHerminio';
import Footer from '../../../../Components/Footer/Footer';
import logoToast from '../../../../assets/logo/LogoDiagrama.png';
import stripe_logo from '../../../../assets/img/stripe_logo.webp';
import './herminio.css';

const Herminio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const StripeHerminio = () => {
    window.location.href = 'https://buy.stripe.com/00g5kt7FBfd1dDW28k';
  };

  const { actualizarCarrito } = useCarrito();
  const [showToast, setShowToast] = useState(false);

  const HerminioProducto = () => {
    actualizarCarrito((carritoActual) => {
    const nuevoProducto = {
      imagen: herminioIMG,
      id: "price_1O2fSQDyGma9TCw9utyXMF2c",
      nombre: 'Herminio',
      preciounit: 45120,
      precio: 45120,
      cantidad: 1,
    };

    const productoExistente = carritoActual.find((producto) => producto.id === nuevoProducto.id);

    if (productoExistente) {
      productoExistente.cantidad += 1;
    } else {
      carritoActual.push(nuevoProducto);
    }

    setShowToast(true);
    return [...carritoActual];
  });
};

  return (
    <>
    <NavigationBar/>
    <div className='container-fluid'>
        <section>
          <div className='row pt-3'>

            <div className='col-sm-6 col-md-6 col-lg-6'>
              <img src={herminioInicio} alt="" className='imagen' />
            </div>

            <div className='col-sm-6 col-md-6 col-lg-6 d-flex flex-column justify-content-center align-items-center'>
              <div className='text-start m-3'>
                <h2>HERMINIO_</h2>
                <p>Carrito auxiliar para lograr un orden en el espacio de trabajo o bien, ofrecer un servicio de alimentos y bebidas con gran eficiencia, una pieza que busca complementar espacios interiores, su carácter multifuncional contribuye a las diferentes actividades de la vida cotidiana.</p>
                {/* <h2 className='pt-3'>$45,120.00</h2> */}

                <div className='col-sm-6 col-md-6 col-lg-8 justify-content-start'>
                <img src={usos} alt="" className='imagen' />
              </div>

              <div className='text-start'>
                {/* <button className='elementor-button-clara' onClick={HerminioProducto}>Agregar al Carrito</button>
                <br />
                <p className='pt-3'><button className='elementor-button-clara' onClick={StripeHerminio}>Comprar</button></p> */}
              </div>
              </div>

        <Toast show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide className='color-toast'>
        <div className=''>
          <img src={logoToast} className="rounded me-2 imgToast" alt="" />
          <b>Producto Añadido al Carrito</b>
          </div>
        </Toast>

            </div>

          </div>
        </section>

        <GaleryHerminio/>

        <div className='contenedorIMG'>
          <img src={vista} alt="" className='homeImg' />
        </div>

        <div className='contenedorIMG'>
          <img src={dimensiones} alt="" className='homeImg' />
        </div>

        <Container fluid className="pt-5">
          <div className="row">
            <div className='col-sm-12 col-md-6 col-lg-6 m-0'><img src={foto1} alt="" className='imagenjunta1' /></div>
            <div className='col-sm-12 col-md-6 col-lg-6 m-0 pt-2'> <img src={foto2} alt="" className='imagenjunta2' /></div>
          </div>
          <h2 className='text-center pt-3'>Posibles configuraciones y usos</h2>
        </Container>

        <div className='contenedorIMG'>
          <img src={config1} alt="" className='homeImg' />
        </div>

        <div className='contenedorIMG'>
          <img src={config2} alt="" className='homeImg' />
        </div>

        <div className='contenedorIMG'>
          <img src={final} alt="" className='homeImg' />
        </div>

    </div>
    <Footer/>
    </>
  );
}

export default Herminio; 