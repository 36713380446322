import React from 'react';
import { Link } from 'react-router-dom';
import './flotante.css';
import logo from '../../../assets/logo/LogoDiagrama.png';

const Flotante = () => {
  return (
      <>
          <Link to="/contacto"><a className="floating-button">
              <div className="button-content">
                  <span>Agenda tu cita</span>
                  <img src={logo} alt="Botón Flotante" />
              </div>
          </a></Link>
      </>
  );
}

export default Flotante;