import React, {useState, useEffect} from 'react';
import NavigationBar from '../../../../Components/Nav/NavigationBar';
import { useCarrito } from '../../../Carrito/CarritoContext';
import { Container, Row, Col, Toast } from 'react-bootstrap';
import urielPoloInicio from '../../../../assets/Productos/uriel_y_polo/Fotos/Uriel_y_polo_foto inicio.webp';
import urielIMG from '../../../../assets/carrito/Uriel.webp';
import poloIMG from '../../../../assets/carrito/Polo.webp';
import usos from '../../../../assets/Productos/uriel_y_polo/Fotos/usos.png';
import uriel from '../../../../assets/Productos/uriel_y_polo/Fotos/uriel.png';
import polo from '../../../../assets/Productos/uriel_y_polo/Fotos/polo.png';
import GaleryUriel from '../../../../Components/CarrouselProductos/UrielCarrousel/GaleryUriel';
import señalizacion from '../../../../assets/Productos/uriel_y_polo/Señalizacion_materialidad/Uriel_y_polo_Señalizacion_materialidad.webp';
import dimensiones from '../../../../assets/Productos/uriel_y_polo/Dimensiones/Polo_y_Uriel_dimensiones.webp';
import foto1 from '../../../../assets/Productos/uriel_y_polo/Fotos/Uriel_y_polo_izquierda.webp';
import foto2 from '../../../../assets/Productos/uriel_y_polo/Fotos/Uriel_y_polo_derecha.webp';
import config1 from '../../../../assets/Productos/uriel_y_polo/Configuraciones/Uriel_y_polo_configuracion_1.webp';
import config2 from '../../../../assets/Productos/uriel_y_polo/Configuraciones/Uriel_y_polo_configuracion_2.webp';
import final from '../../../../assets/Productos/uriel_y_polo/Fotos/Uriel_y_polo_inferior.webp'; 
import Footer from '../../../../Components/Footer/Footer';
import stripe_logo from '../../../../assets/img/stripe_logo.webp';
import logoToast from '../../../../assets/logo/LogoDiagrama.png';


import './uriel.css';

const Uriel = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const StripeUriel = () => {
    window.location.href = 'https://buy.stripe.com/00gdQZe3Ze8X2Zi8wC';
  };

  const StripePolo = () => {
    window.location.href = 'https://buy.stripe.com/7sI9AJ1hde8XdDW4gn';
  };

  const { actualizarCarrito } = useCarrito();
  const [showToast, setShowToast] = useState(false);

  const UrielProducto = () => {
    actualizarCarrito((carritoActual) => {
    const nuevoProducto = {
      imagen: urielIMG,
      id: "price_1O2f4oDyGma9TCw9V2dhZjoN",
      nombre: 'Sillon Uriel',
      preciounit: 47590,
      precio: 47590,
      cantidad: 1,
    };

    const productoExistente = carritoActual.find((producto) => producto.id === nuevoProducto.id);

    if (productoExistente) {
      productoExistente.cantidad += 1;
    } else {
      carritoActual.push(nuevoProducto);
    }

    setShowToast(true);
    return [...carritoActual];
  });
};

const PoloProducto = () => {
  actualizarCarrito((carritoActual) => {
  const nuevoProducto = {
    imagen: poloIMG,
    id: "price_1O2f2wDyGma9TCw9fOOYkk6U",
    nombre: 'Sillon Polo',
    preciounit: 22730,
    precio: 22730,
    cantidad: 1,
  };

  const productoExistente = carritoActual.find((producto) => producto.id === nuevoProducto.id);

  if (productoExistente) {
    productoExistente.cantidad += 1;
  } else {
    carritoActual.push(nuevoProducto);
  }
  setShowToast(true);
  return [...carritoActual];
});
};
  return (
    <>
      <NavigationBar />
      <div className='container-fluid'>
        <section>
          <div className='row pt-3'>

            <div className='col-sm-6 col-md-6 col-lg-6'>
              <img src={urielPoloInicio} alt="" className='imagen' />
            </div>

            <div className='col-sm-6 col-md-6 col-lg-6 d-flex flex-column justify-content-center align-items-center'>
              <div className='text-start m-3'>
                <h2>URIEL & POLO_</h2>
                <p>Un binomio extraordinario y cómodo. Integrado por dos elementos que abrazan al usuario de asiento a respaldo. Funcionan en conjunto y también por separado con la posibilidad de crear una sala modular, tan extensa como se requiera.</p>

                <div className='col-sm-6 col-md-6 col-lg-8 justify-content-start'>
                <img src={usos} alt="" className='imagen' />
              </div>
              </div>

              <div className='row'>
                <div className='col-sm-6 col-md-6 col-lg-6'>
                  <h2 className='m-3'>URIEL</h2>
                  <img src={uriel} alt="sofa_1" className='producto' />
                  {/* <h2 className='m-3'>$47,590.00</h2> */}
                  <div className='text-center'>
                    {/* <button className='elementor-button' onClick={UrielProducto}>Agregar al Carrito</button>
                    <br />
                <p className='pt-3'><button className='elementor-button-clara' onClick={StripeUriel}>Comprar</button></p> */}
                  </div>

                </div>

                <div className='col-sm-6 col-md-6 col-lg-6'>
                  <h2 className='m-3'>POLO</h2>
                  <img src={polo} alt="sofa_2" className='producto' />
                  {/* <h2 className='m-3'>$22,730.00</h2> */}
                  <div className='text-center'>
                    {/* <button className='elementor-button' onClick={PoloProducto}>Agregar al Carrito</button>
                    <br />
                <p className='pt-3'><button className='elementor-button-clara' onClick={StripePolo}>Comprar</button></p> */}
                  </div>
                </div>
              </div>


              <Toast show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide className='color-toast'>
        <div className=''>
          <img src={logoToast} className="rounded me-2 imgToast" alt="" />
          <b>Producto Añadido al Carrito</b>
          </div>
        </Toast>

            </div>

          </div>
        </section>

        <GaleryUriel/>

        <div className='contenedorIMG'>
          <img src={señalizacion} alt="" className='homeImg' />
        </div>

        <div className='contenedorIMG'>
          <img src={dimensiones} alt="" className='homeImg' />
        </div>

        <Container fluid className="pt-5">
          <div className="row">
            <div className='col-sm-12 col-md-6 col-lg-6 m-0'><img src={foto1} alt="" className='imagenjunta1' /></div>
            <div className='col-sm-12 col-md-6 col-lg-6 m-0 pt-2'> <img src={foto2} alt="" className='imagenjunta2' /></div>
          </div>
          <h2 className='text-center pt-3'>Posibles configuraciones y usos</h2>
        </Container>

        <div className='contenedorIMG'>
          <img src={config1} alt="" className='homeImg' />
        </div>

        <div className='contenedorIMG'>
          <img src={config2} alt="" className='homeImg' />
        </div>

        <div className='contenedorIMG'>
          <img src={final} alt="" className='homeImg' />
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default Uriel;
