import React, {useState, useEffect} from 'react';
import NavigationBar from '../../../../Components/Nav/NavigationBar';
import { useCarrito } from '../../../Carrito/CarritoContext';
import { Container, Row, Col, Toast} from 'react-bootstrap';
import linaInicio from '../../../../assets/Productos/lina/Fotos/Lina_foto_inicio.webp';
import linaIMG from '../../../../assets/carrito/Lina.webp';
import usos from '../../../../assets/Productos/uriel_y_polo/Fotos/usos.png';
import vista from '../../../../assets/Productos/lina/Señalizacion_de_materialidad/Lina_Señalizacion_materialidad.webp';
import dimensiones from '../../../../assets/Productos/lina/Dimensiones/Lina_dimensiones.webp';
import foto1 from '../../../../assets/Productos/lina/Fotos/Lina_izquierda.webp';
import foto2 from '../../../../assets/Productos/lina/Fotos/Lina_derecha.webp';
import config1 from '../../../../assets/Productos/lina/Configuraciones/Clara_y_lina_configuracion_1.webp';
import config2 from '../../../../assets/Productos/lina/Configuraciones/Clara_y_lina_configuracion_2.webp';
import final from '../../../../assets/Productos/lina/Fotos/Lina_inferior.webp';
import GaleryLina from '../../../../Components/CarrouselProductos/LinaCarrousel/GaleryLina';
import Footer from '../../../../Components/Footer/Footer';
import stripe_logo from '../../../../assets/img/stripe_logo.webp';
import logoToast from '../../../../assets/logo/LogoDiagrama.png';
import './lina.css';

const Lina = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const StripeLina = () => {
    window.location.href = 'https://buy.stripe.com/dR6aEN5xte8X1VeeV4';
  };

  const { actualizarCarrito } = useCarrito();
  const [showToast, setShowToast] = useState(false);

  const LinaProducto = () => {
    actualizarCarrito((carritoActual) => {
    const nuevoProducto = {
      imagen: linaIMG,
      id: "price_1O2fO8DyGma9TCw96JrCdlEH",
      nombre: 'Herminio',
      preciounit: 29360,
      precio: 29360,
      cantidad: 1,
    };

    const productoExistente = carritoActual.find((producto) => producto.id === nuevoProducto.id);

    if (productoExistente) {
      productoExistente.cantidad += 1;
    } else {
      carritoActual.push(nuevoProducto);
    }
    setShowToast(true);
    return [...carritoActual];
  });
};

  return (
    <>
    <NavigationBar/>
    <div className='container-fluid'>
        <section>
          <div className='row pt-3'>

            <div className='col-sm-6 col-md-6 col-lg-6'>
              <img src={linaInicio} alt="" className='imagen' />
            </div>

            <div className='col-sm-6 col-md-6 col-lg-6 d-flex flex-column justify-content-center align-items-center'>
              <div className='text-start m-3'>
                <h2>LINA_</h2>
                <p>Set de lámparas enfocadas en iluminar desde diferentes alturas el espacio que habitan. Proyectan una iluminación de superficies horizontales, en cada una de sus versiones facilita las actividades del día a día, sea de piso o de mesa. Uno de sus atractivos principales es aprovechar el uso de la cubierta principal como mesa de apoyo para momentos de trabajo o esparcimiento.</p>
                {/* <h2 className='pt-3'>$29,360.00</h2> */}

                <div className='col-sm-6 col-md-6 col-lg-8 justify-content-start'>
                  <img src={usos} alt="" className='imagen' />
                </div>

                <div className='text-start'>
                  {/* <button className='elementor-button-clara' onClick={LinaProducto}>Agregar al Carrito</button>
                  <br />
                <p className='pt-3'><button className='elementor-button-clara' onClick={StripeLina}>Comprar</button></p> */}
                </div>
              </div>

              <Toast show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide className='color-toast'>
                <div className=''>
                  <img src={logoToast} className="rounded me-2 imgToast" alt="" />
                  <b>Producto Añadido al Carrito</b>
                </div>
              </Toast>

            </div>
          </div>
        </section>

        <GaleryLina/>

        <div className='contenedorIMG'>
          <img src={vista} alt="" className='homeImg' />
        </div>

        <div className='contenedorIMG'>
          <img src={dimensiones} alt="" className='homeImg' />
        </div>

        <Container fluid className="pt-5">
          <div className="row">
            <div className='col-sm-12 col-md-6 col-lg-6 m-0'><img src={foto1} alt="" className='imagenjunta1' /></div>
            <div className='col-sm-12 col-md-6 col-lg-6 m-0 pt-2'> <img src={foto2} alt="" className='imagenjunta2' /></div>
          </div>
          <h2 className='text-center pt-3'>Posibles configuraciones y usos</h2>
        </Container>

        <div className='contenedorIMG'>
          <img src={config1} alt="" className='homeImg' />
        </div>

        <div className='contenedorIMG'>
          <img src={config2} alt="" className='homeImg' />
        </div>

        <div className='contenedorIMG'>
          <img src={final} alt="" className='homeImg' />
        </div>
    </div>
    <Footer/>
    </>
  );
}

export default Lina;