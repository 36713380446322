import React, {useState, useEffect} from 'react';
import NavigationBar from '../../../../Components/Nav/NavigationBar';
import { useCarrito } from '../../../Carrito/CarritoContext';
import { Container, Row, Col, Toast} from 'react-bootstrap';
import usos from '../../../../assets/Productos/uriel_y_polo/Fotos/usos.png';
import danteInicio from '../../../../assets/Productos/dante/Fotos/Dante_foto_inicio.webp';
import danteIMG from '../../../../assets/carrito/Dante.webp';
import vista from '../../../../assets/Productos/dante/Señalizacion_materialidad/Dante_Señalizacion_materialidad.webp';
import dimensiones from '../../../../assets/Productos/dante/Dimensiones/Dante_dimensiones.webp';
import foto1 from '../../../../assets/Productos/dante/Fotos/Dante_izquierda.webp';
import foto2 from '../../../../assets/Productos/dante/Fotos/Dante_derecha.webp';
import config1 from '../../../../assets/Productos/dante/Configuraciones/Dante_configuracion_1.webp';
import config2 from '../../../../assets/Productos/dante/Configuraciones/Dante_configuracion_2.webp';
import final from '../../../../assets/Productos/dante/Fotos/Dante_inferior.webp';
import GaleryDante from '../../../../Components/CarrouselProductos/DanteCarrousel/GaleryDante';
import Footer from '../../../../Components/Footer/Footer';
import stripe_logo from '../../../../assets/img/stripe_logo.webp';
import logoToast from '../../../../assets/logo/LogoDiagrama.png';
import './dante.css';

const Dante = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const StripeDante = () => {
    window.location.href = 'https://buy.stripe.com/4gw28hf83gh5czSaER';
  };


  const { actualizarCarrito } = useCarrito();
  const [showToast, setShowToast] = useState(false);

  const DanteProducto = () => {
    actualizarCarrito((carritoActual) => {
    const nuevoProducto = {
      imagen: danteIMG,
      id: "price_1O2fTMDyGma9TCw9R8QRk3V6",
      nombre: 'Herminio',
      preciounit: 15700,
      precio: 15700,
      cantidad: 1,
    };

    const productoExistente = carritoActual.find((producto) => producto.id === nuevoProducto.id);

    if (productoExistente) {
      productoExistente.cantidad += 1;
    } else {
      carritoActual.push(nuevoProducto);
    }
    setShowToast(true);
    return [...carritoActual];
  });
};


  return (
    <>
    <NavigationBar/>
    <div className='container-fluid'>
        <section>
          <div className='row pt-3'>

            <div className='col-sm-6 col-md-6 col-lg-6'>
              <img src={danteInicio} alt="" className='imagen' />
            </div>

            <div className='col-sm-6 col-md-6 col-lg-6 d-flex flex-column justify-content-center align-items-center'>
              <div className='text-start m-3'>
                <h2>DANTE_</h2>
                <p>Un contraste de funcionalidad, para la organización de un espacio creativo o bien, para el servicio de flores y entradas deliciosas que acompañan una copa de vino, una pieza elemento que buscan complementar lo momentos de convivencia entre amigos y familia.</p>
                {/* <h2 className='pt-3'>$15,700.00</h2> */}

                <div className='col-sm-6 col-md-6 col-lg-8 justify-content-start'>
                  <img src={usos} alt="" className='imagen' />
                </div>

                <div className='text-start'>
                  {/* <button className='elementor-button-dante' onClick={DanteProducto}>COMPRAR</button>
                  <br />
                <p className='pt-3'><button className='elementor-button-clara' onClick={StripeDante}>Comprar</button></p> */}
                </div>
              </div>

              <Toast show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide className='color-toast'>
        <div className=''>
          <img src={logoToast} className="rounded me-2 imgToast" alt="" />
          <b>Producto Añadido al Carrito</b>
          </div>
        </Toast>

            </div>
          </div>
        </section>

        <GaleryDante/>

        <div className='contenedorIMG'>
          <img src={vista} alt="" className='homeImg' />
        </div>

        <div className='contenedorIMG'>
          <img src={dimensiones} alt="" className='homeImg' />
        </div>

        <Container fluid className="pt-5">
          <div className="row">
            <div className='col-sm-12 col-md-6 col-lg-6 m-0'><img src={foto1} alt="" className='imagenjunta1' /></div>
            <div className='col-sm-12 col-md-6 col-lg-6 m-0 pt-2'> <img src={foto2} alt="" className='imagenjunta2' /></div>
          </div>
          <h2 className='text-center pt-3'>Posibles configuraciones y usos</h2>
        </Container>

        <div className='contenedorIMG'>
          <img src={config1} alt="" className='homeImg' />
        </div>

        <div className='contenedorIMG'>
          <img src={config2} alt="" className='homeImg' />
        </div>

        <div className='contenedorIMG'>
          <img src={final} alt="" className='homeImg' />
        </div>

    </div>
    <Footer/>
    </>
  );
}

export default Dante;